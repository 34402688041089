import React from "react";
import DeleteAccountController, {
    Props,
} from "./DeleteAccountController.web";
import OutlinedInput from '@mui/material/OutlinedInput';

import { Formik } from "formik";
import * as Yup from "yup";
import { Box, Button, InputLabel, Typography, Snackbar, Alert } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

export default class DeleteAccount extends DeleteAccountController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                marginTop: 5,
                gap: "20px",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <Typography variant="h5" style={{ color: "#ffffff", margin: '10px 0px' }}>
                    Delete Account
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: 400, fontSize: '16px', margin: '10px 0px' }}>
                    We understand that sometimes you may want to say goodbye. If you wish to permanently delete your account and all associated data, you can do so here. Please note that this action is irreversible.
                </Typography>
                <Formik
                    initialValues={{ currentPassword: "" }}
                    validationSchema={
                        Yup.object().shape({
                            currentPassword: Yup.string().required("Current Password is required"),
                        })
                    }
                    validateOnMount={true}
                    validateOnChange={true}
                    onSubmit={(values) => {
                    this.handleOpen()
                    }}
                    data-testid="passForm"
                >
                    {({
                        handleChange,
                        handleSubmit,
                        errors,
                        touched,
                        values
                    }) => (
                        <>
                            <Box
                                width={'65%'}
                                display={'flex'}
                                justifyContent={'center'}
                                flexDirection={'column'}
                                alignItems={'flex-start'}
                            >
                                <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}>Enter your password</InputLabel>
                                <OutlinedInput
                                    fullWidth
                                    style={webStyles.inputLbl}
                                    value={values.currentPassword}
                                    id="outlined-pass"
                                    data-test-id='currentPass'
                                    onChange={(e) => {
                                        handleChange("currentPassword")(e);  
                                        this.handlePasswordChange(e.target.value);                 
                                      }}
                                    type={this.state.showPassword ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => this.handleClickShowPassword()}
                                                edge="end"
                                                data-testid="passIcon"
                                            >
                                                {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    placeholder={"Current Password"}
                                />
                                {(errors.currentPassword && touched.currentPassword) ? (
                                    <span style={webStyles.errorStyle}>
                                        {errors.currentPassword}
                                    </span>
                                ) : null}
                                 {(this.state.passwordError !== "") ? (
                                    <span style={webStyles.errorStyle}>
                                        {this.state.passwordError}
                                    </span>
                                ) : null}

                            </Box>
                            <Button
                                variant="outlined"
                                data-test-id={"DeleteBtn"}
                                onClick={() => handleSubmit()}
                                sx={{
                                    textTransform: "none",
                                    borderColor: "#DC2626",
                                    color: "#DC2626",
                                    padding: "16px 24px",
                                    borderRadius: '8px',
                                    fontWeight: 700
                                }}
                            >
                                Delete Account
                            </Button>
                        </>
                    )}
                </Formik>

                <Dialog
                    sx={{
                        borderRadius: '8px',
                        '& .MuiDialog-paper': {
                            backgroundColor: '#000000',
                        },
                        padding: '40px 16px',
                    }}

                    open={this.state.open}
                    aria-describedby="decline-modal-description"
                    onClose={this.handleClose}
                    aria-labelledby="decline-modal-title"
                >
                    <DialogTitle id="decline-modal-title">
                        <Typography variant="h6" sx={{
                            fontSize: '32px',
                            fontWeight: 700,
                            textAlign: "center"
                        }}><span style={{ color: "#ffffff" }}>
                               Are you sure you want
                            </span>
                            <br/>
                            <span style={{ color: "#C2B30A" }}>
                                Delete Account?
                            </span>
                        </Typography>
                        <Typography sx={{
                            fontSize: '14px',
                            padding: "5px 5px",
                            fontWeight: 400,
                            color: "#ffffff",
                            textAlign: "center"
                        }} variant="body1">
                           We will be happy seeing you again
                        </Typography>
                    </DialogTitle>

                    <DialogActions
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: '16px',
                        }}
                    >
                        <Button
                            onClick={()=>this.deleteAcc(this.state.password)}
                            sx={{
                                height: '55px',
                                width: '343px',
                                borderRadius: '8px',
                                textTransform: 'none',
                                padding: '16px 24px',
                                backgroundColor: '#C2B30A',
                                color: '#ffffff',
                                '&:hover': {
                                    backgroundColor: '#a59a00',
                                },
                                fontWeight: 700,
                                fontSize: '18px',
                            }}
                            data-test-id={'deletebtn'}
                        >Delete account</Button>
                        <Button
                            onClick={this.handleClose1}
                            sx={{
                                height: '55px',
                                width: '343px',
                                borderRadius: '8px',
                                textTransform: 'none',
                                padding: '16px 24px',
                                backgroundColor: 'black',
                                color: '#ffffff',
                                '&:hover': {
                                    backgroundColor: '#333333',
                                },
                                fontWeight: 700,
                                fontSize: '18px',
                                border:"1px solid white"
                            }}
                            data-test-id={'continueBtn'}
                        >Cancel</Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    sx={{
                        borderRadius: '8px',
                        '& .MuiDialog-paper': {
                            backgroundColor: '#000000',
                        },
                        padding: '40px 16px',
                    }}

                    open={this.state.open2}
                    aria-describedby="decline-modal-description"
                    onClose={this.handleClose}
                    aria-labelledby="decline-modal-title"
                >
                    <DialogTitle id="decline-modal-title">
                        <Typography variant="h6" sx={{
                            fontSize: '32px',
                            fontWeight: 700,
                            textAlign: "center"
                        }}>
                            <span style={{ color: "#C2B30A" }}>
                                Success:
                            </span>
                            <span style={{ color: "#ffffff" }}>
                                Your Account has been deleted!
                            </span>
                        </Typography>
                        <Typography sx={{
                            fontSize: '14px',
                            padding: "5px 5px",
                            fontWeight: 400,
                            color: "#ffffff",
                            textAlign: "center"
                        }} variant="body1">
                            We hope you come back soon!!
                        </Typography>
                    </DialogTitle>

                    <DialogActions
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: '16px',
                        }}
                    >
                        <Button
                            onClick={this.handleClose}
                            sx={{
                                width: '343px',
                                height: '55px',
                                padding: '16px 24px',
                                borderRadius: '8px',
                                color: '#ffffff',
                                backgroundColor: '#C2B30A',
                                '&:hover': {
                                    backgroundColor: '#a59a00',
                                },
                                fontSize: '18px',
                                fontWeight: 700,
                                textTransform: 'none'
                            }}
                            data-test-id={'continueBtn'}
                        >Continue</Button>
                    </DialogActions>
                </Dialog>
            </Box>
        )
    }
}

const webStyles = {
    inputLbl: {
        color: "#FFFFFF",
        borderRadius: 8,
        backgroundColor: "#57534E",
        paddingBottom: "3px",
        marginBottom: "3px"
    },

    errorStyle: {
        color: "red",
        textAlign: "center" as const,
        fontSize: 12
    },
};