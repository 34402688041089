import React from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
// Customizable Area Start
import { Box , Typography} from "@mui/material";
// Customizable Area End
import LegalController, {
  Props,
} from "./LegalController.web";

export default class Legal extends LegalController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    const queryParams = new URLSearchParams(window.location.search);
    const initialTab = queryParams.get("tab") === "term" ? "two" : "one";
    this.state = {
    tab: initialTab, 
    TermsAndConditions: "",       
    PrivacyPolicy: "",    
    termsCreatedAt: "",
    termsUpdatedAt: "",
    privacyCreatedAt: "",
    privacyUpdatedAt: ""
      };
    // Customizable Area End
  }

  // Customizable Area Start
  
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
    <Box sx={{ width: '100%', height: "100%", backgroundColor: '#292524'}}>
          <Typography variant="h6" sx={{
          fontSize: '28px',
          fontWeight: 700,
        }}>
          <span style={{ color: "#C2B30A" }}>
          Legal 
          </span>
          
        </Typography>
    <Tabs
      value={this.state.tab}
      sx={{
        '& .MuiTabs-indicator': {
          backgroundColor: '#C2B30A',
        }
      }}
      onChange={(e, v) => this.handleChange(e, v)}
      aria-label="secondary tabs example"
      centered
      variant="fullWidth"
      data-test-id="tab"
    >
      <Tab value="one"
        sx={{
          color: '#ffffff',
          textTransform: 'none',
          fontWeight: '700',
          '&.Mui-disabled': {
            color: '#78716C',
            opacity: 1,
            pointerEvents: 'none',
          },
          '&.Mui-selected': {
            color: '#ffffff',
          },
        }} label="Terms and Conditions" />
      <Tab value="two"
        sx={{
          color: '#ffffff',
          fontWeight: '700',
          textTransform: 'none',
          '&.Mui-disabled': {
            pointerEvents: 'none',
            color: '#78716C',
            opacity: 1,
          },
          '&.Mui-selected': {
            color: '#ffffff',
          },
        }} label="Privacy policy" />
    </Tabs>
    {/* Display content based on selected tab */}
    {this.state.tab === 'one' && (
        <Typography variant="h5" sx={{ fontSize: '28px',
            fontWeight: 700, marginTop: '20px'}}>
        {/* <span style={{ color: "#C2B30A" }}>User{' '}</span>  */}
        <span style={{  color: '#ffffff' }}>Terms and Conditions</span>    
        <Typography variant="body2" sx={{ marginTop: '5px', color: '#ffffff' }}>
  <span style={{ color: "#A8A29E"}}>Last Update:</span> {this.state.termsUpdatedAt ? new Date(this.state.termsUpdatedAt).toLocaleDateString() : 'N/A'}
</Typography>
        <Typography variant="body1" sx={{ marginTop: '20px', color: '#ffffff', fontWeight:400,fontSize:'14px'}}>
       {this.state.TermsAndConditions}
  </Typography>
  
        </Typography>
      )}
      {this.state.tab === 'two' && (
        <Typography variant="h5" sx={{ fontSize: '28px',
            fontWeight: 700, marginTop: '20px'}}>
          <span style={{ color: "#ffffff" }}>Privacy Policy</span>
          <Typography  variant="body1" sx={{ marginTop: '20px', color: '#ffffff'}}>
    {this.state.PrivacyPolicy}
  </Typography>
        </Typography>
      )}
      </Box>
      )
    }
      // Customizable Area End
};
// Customizable Area Start
// Customizable Area End
