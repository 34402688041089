import { BlockComponent } from "../../../framework/src/BlockComponent";
export const configJSON = require("./config");
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export interface Props {
    history: { push: any };
}

interface S {
    showPassword: boolean,
    open: boolean,
    password: string,
    showToast: boolean,
    open2: boolean,
    passwordError:string
}

interface SS {
}

export default class DeleteAccountController extends BlockComponent<
    Props,
    S,
    SS
> {
    apiDeleteAccountId: string = "";

    constructor(props: Props) {
        super(props);

        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];

        this.state = {
            showPassword: false,
            open: false,
            password: "",
            showToast: false,
            open2 : false,
            passwordError:""
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {

        if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
            let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))

            if (apiRequestCallId == this.apiDeleteAccountId) {
               this.handleModal(responseJson);
            }
        }

    }

    async componentDidMount() {
        this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
        // Customizable Area Start
        // Customizable Area End
    }

    handleClose = () => {
        this.setState({
            open2: false,
        }, () => this.props.history.push("/"));
    }
    handleClose1 = () => {
        this.setState({
            open: false,
        })
    }


    handleOpen = () => {
        this.setState({
            open: true,
        });
    }

    handleOpen2 = () =>{
        this.setState({
            open2: true
        })
    }


    handleClickShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword })
    };

    handlePasswordChange = (value:string) => {
        this.setState({ password:value,passwordError:""})
    };


    handleModal = (responseJson:any) => {
        if (responseJson.message === "Your account has been successfully deleted") {
            this.handleOpen2();
            this.handleClose1();
            localStorage.clear();
        }else{
            this.setState({passwordError:responseJson.errors})
            this.handleClose1();
        }
    };

    deleteAcc = (values: any) => {

        const token: any = localStorage.getItem('authToken');
        let formData = new FormData()
        formData.append("password", values);
        let body = formData;

        const header = {
            "Accept": "*/*",
            "token": token
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiDeleteAccountId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.deleteAccount
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            body
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.methodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

}
