import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
export const configJSON = require("./config");

// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  openbackry: any
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  tab: string;
  isAcceptOrDeliver: boolean;
  type: string;
  isDecline: boolean;
  isBakeryOpen: boolean;
  isStoreOpen: boolean;
  orders: {
    to_accept: any[],
    preparing: any[],
    delivered: any[]
  },
  orderId: any;
  // Customizable Area End
}
interface SS {}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  getOrdersListApiCallId: any = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      tab: "one",
      isStoreOpen: false,
      isAcceptOrDeliver: false,
      type: "",
      isDecline: false,
      isBakeryOpen: false,
      orders: {
        to_accept: [],
        preparing: [],
        delivered: []
      },
      orderId: "",
    }

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    this.getOrdersList();
    const isBakeryOpen = localStorage.getItem('isBakeryOpen');
    const isStoreOpen = localStorage.getItem('isStoreOpen');
    this.setState({
      isBakeryOpen: isBakeryOpen ? true : false,
      isStoreOpen: isStoreOpen ? true : false,
    });

    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    const webHeader = {};
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.dashboardGetUrl
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) return;
    let apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.getOrdersListApiCallId) {
      this.setState({ orders: responseJson });
    }
    // Customizable Area End
  }
  // Customizable Area Start

  getOrdersList = () => {
    let token = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getOrdersListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.ordersUrl}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  openAcceptOrDeliverModal = (type: string, id: any) => {
    this.setState({ isAcceptOrDeliver: true, type, orderId: id });
  };

  openDeclineModal = () => {
    this.setState({ isDecline: true });
  };

  openBakeryOpenModal = () => {
    this.setState({ isBakeryOpen: true });
  };

  closeAcceptOrDeliverModal = () => {
    this.setState({ isAcceptOrDeliver: false });
  };

  closeDeclineModal = () => {
    this.setState({ isDecline: false });
  };

  closeBakeryOpenModal = () => {
    this.setState({ isBakeryOpen: false });
  };

  openStore = () => {
    this.setState({ isStoreOpen: true }, () => {
      this.props.openbackry(true);
      localStorage.setItem('isStoreOpen', JSON.stringify(true));
    });
  };

  handleChange = (event: any, newValue: string) => {
    this.setState({ tab: newValue });
  };

  formatTime = (dateString: any) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString('en-US', { hour12: false });
  };


  // Customizable Area End
}
