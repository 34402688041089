// Customizable Area Start
import React from 'react';
import {
    StyleSheet,
} from "react-native";
import { Typography, Button, InputLabel, Snackbar, Alert } from "@mui/material";
import OutlinedInput from '@mui/material/OutlinedInput';
import { Autocomplete, GoogleMap } from '@react-google-maps/api';


// Customizable Area End

import LocationController, { Props } from "./LocationController.web";

export default class Location extends LocationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }

    // Customizable Area Start
    center = {
        lat: 28.626137,
        lng: 79.821603,
    };

    // Customizable Area End
    render() {
        return (
            // Customizable Area Start
            <div style={styles.main}>
                <Snackbar
                    open={this.state.showToast}
                    autoHideDuration={6000}
                    onClose={this.handleCloseToast}
                >
                    <Alert onClose={this.handleCloseToast} severity={this.state.toastSeverity}>
                        {this.state.toastMessage}
                    </Alert>
                </Snackbar>
                <div style={styles.wrapper}>
                    <img style={styles.banner} src={require("../../email-account-login/assets/banner.png")} />
                    <div style={{
                        content: '',
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        background: "linear-gradient(to right, rgba(0,0,0,0) 94%, rgb(0, 0, 0) 100%)",
                        zIndex: 1
                    }}></div>
                </div>
                <div style={styles.body} data-test-id='mainContainer'>
                    {
                        this.state.isLoaded ? (
                            <GoogleMap
                                mapContainerStyle={styles.containerStyle}
                                center={this.center}
                                onLoad={this.onLoadMap}
                                zoom={10}
                                data-test-id='main'
                            >

                                <div style={styles.container} data-test-id='container'>
                                    <form style={styles.form} >
                                        <div style={styles.inputWrapper}>
                                            <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}>Address</InputLabel>
                                            <Autocomplete
                                                onLoad={this.onLoadAutocomplete}
                                                onPlaceChanged={this.handlePlaceChanged}
                                            >
                                                <OutlinedInput
                                                    fullWidth
                                                    style={styles.inputLabel}
                                                    id="outlined-adornment-address"
                                                    type={'text'}
                                                    placeholder={"6391 Elgin St. Celina, Delaware 102"}
                                                    onChange={this.handleInputChange('address')}                                                    value={this.state.address}
                                                    data-test-id='addressInput'
                                                    name='address'
                                                />
                                            </Autocomplete>
                                        </div>
                                        <div style={styles.inputWrapper}>
                                            <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}>Additional info</InputLabel>
                                            <OutlinedInput
                                                fullWidth
                                                style={styles.inputLabel}
                                                id="outlined-adornment-info"
                                                type={'text'}
                                                placeholder={"Block, number, near to..."}
                                                onChange={this.handleInputChange('additionalInfo')}
                                                value={this.state.additionalInfo}
                                                data-test-id='infoInput'
                                                name='moreInfo'
                                            />
                                        </div>
                                        <div
                                            style={styles.buttonsWrapper}
                                        >
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                size="large"
                                                data-test-id={"saveBtn"}
                                                onClick={() => this.handleSubmit()}
                                                style={styles.button}
                                            >Save Location
                                            </Button>
                                        </div>
                                        <div
                                            style={styles.arrowWrapper}
                                        >
                                            <img
                                                src={require('../../email-account-login/assets/back_arrow.png')}
                                                style={styles.backArrow}
                                            />
                                            <Typography style={styles.noAccountLabel} >
                                                Back
                                            </Typography>
                                        </div>
                                    </form>
                                </div>

                            </GoogleMap>
                        ) : (
                            <div>Loading map...</div>
                        )
                    }

                </div>
            </div>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const styles = StyleSheet.create({

    containerStyle: {
        width: '100%',
        height: '100%',
    },

    main: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#000000",
        fontSize: 25,
    },

    inputWrapper: {
        flex: 1,
        margin: "20px",
        width: "90%"
    },

    noAccountLabel: {
        fontSize: 16,
        color: "#ffffff",
        fontWeight: "700"
    },

    buttonsWrapper: {
        flex: 1,
        padding: "20px",
        paddingTop: "0px",
        paddingBottom: "0px",
        width: "90%"
    },
    button: {
        backgroundColor: "#C2B30A",
        color: "#ffffff",
        padding: "15px",
        fontWeight: "700"
    },
    backArrow: {
        height: 30,
        width: 30,
    },
    arrowWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px"
    },

    wrapper: {
        flex: 1,
        position: "relative",
    },
    banner: {
        width: "100%",
        height: "100vh"
    },

    inputLabel: {
        color: "#A8A29E",
        padding: "5px 15px",
        borderRadius: 8,
        backgroundColor: "#57534E",
    },
    body: {
        flex: 1,
        display: "flex",
        flexDirection: "column" as const,
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "10px",
        width: "40%",
        height: "100%"
    },
    container: {
        backgroundColor: "black",
        padding: "20px",
        borderRadius: 10,
        width: "50%",
        margin: "0 auto",
        color: "#fff",
        position: "absolute",
        left: "25%",
        bottom: 0,
    },
    form: {
        display: "flex",
        flexDirection: "column",
    },
});
// Customizable Area End
