import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Dialog, DialogActions, DialogTitle, Typography } from "@mui/material";

type MyProps = {
  testID: string;
  isOpen: boolean;
  onClose: () => void;
  title: string;
  message: string;
  buttonText: string;
};

type MyState = {
  open: boolean;
};

export default class CustomDialog extends Component<MyProps, MyState> {
  static propTypes = {
    testID: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
  };

  constructor(props: MyProps) {
    super(props);
    this.state = {
      open: props.isOpen,
    };
  }

  componentDidUpdate(prevProps: MyProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({ open: this.props.isOpen });
    }
  }

  handleClose = () => {
    this.setState({ open: false });
    this.props.onClose();
  };

  render() {
    const { testID, title, message, buttonText } = this.props;
    const { open } = this.state;

    return (
      <Dialog
        open={open}
        onClose={this.handleClose}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        sx={{
          borderRadius: "8px",
          "& .MuiDialog-paper": {
            backgroundColor: "#000000",
          },
          padding: "40px 16px",
        }}
      >
        <DialogTitle id="dialog-title">
          <Typography
            variant="h6"
            sx={{
              fontSize: "32px",
              fontWeight: 700,
              textAlign: "center",
            }}
          >
            <span style={{ color: "#C2B30A" }}>Success: </span>
            <span style={{ color: "#ffffff" }}>{title}</span>
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              padding: "5px 5px",
              fontWeight: 400,
              color: "#ffffff",
              textAlign: "center",
            }}
            variant="body1"
            id="dialog-description"
          >
            {message}
          </Typography>
        </DialogTitle>

        <DialogActions
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "16px",
          }}
        >
          <Button
            onClick={this.handleClose}
            sx={{
              width: "343px",
              height: "55px",
              padding: "16px 24px",
              borderRadius: "8px",
              color: "#ffffff",
              backgroundColor: "#C2B30A",
              "&:hover": {
                backgroundColor: "#a59a00",
              },
              fontSize: "18px",
              fontWeight: 700,
              textTransform: "none",
            }}
            data-test-id={testID}
          >
            {buttonText}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
