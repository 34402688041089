import React from "react";
// Customizable Area Start
import {
  FormControlLabel,
  RadioGroup,
  Radio,
  Checkbox,
  TextField,
  FormControl,
  FormLabel,
  Box, Button, Typography, InputLabel, styled, MenuItem, Select, Snackbar, Alert
} from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
import "@mui/styles";
const DatePicker = require("react-datepicker");
import OutlinedInput from '@mui/material/OutlinedInput';
import { Formik } from "formik";
import * as Yup from "yup";

const CountryCodeSelect = styled(Select)({
  color: "#A8A29E",
  padding: "5px 15px",
  borderRadius: 8,
  backgroundColor: "#57534E",
});
// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

import CustomisableUserProfilesController, {
  Props,
  IField,
} from "./CustomisableUserProfilesController";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

// Customizable Area End

export default class CustomisableUserProfiles extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  getFieldType = (field: IField) => {
    if (
      field.field_type === "integer" ||
      field.field_type === "number" ||
      field.field_type === "float"
    ) {
      return "number";
    }
    return "text";
  };

  renderField = (field: IField, index: number) => {
    if (
      field.field_type === "text" ||
      field.field_type === "string" ||
      field.field_type === "file" ||
      field.field_type === "textarea" ||
      field.field_type === "number" ||
      field.field_type === "integer" ||
      field.field_type === "float"
    ) {
      return (
        <div key={index + "renderField"}>
          <TextField
            data-test-id={field.name}
            label={field.title}
            multiline={field.field_type === "textarea"}
            value={this.state.profile[field.name]}
            onChange={(event) =>
              this.changeFormValue(
                field.name,
                event.target.value,
                field.field_type
              )
            }
            disabled={!field.is_enable}
            required={field.is_required}
            type={this.getFieldType(field)}
            onKeyPress={(event) => this.onKeyPress(event, field)}
            InputLabelProps={{
              shrink: true,
            }}
            // defaultValue='Enter Value'
          />
        </div>
      );
    }

    if (field.field_type === "date" || field.field_type === "datetime") {
      return (
        <div key={index + "renderField"}>
          <div style={{ color: "rgba(0, 0, 0, 0.54)" }}>
            {field.title + (field.is_required ? "*" : "")}
          </div>
          <div style={{ marginBottom: "5px", marginTop: "5px" }}>
            <DatePicker
              data-test-id={field.name}
              selected={
                new Date(
                  this.state.profile[field.name] as string
                ).toString() !== "Invalid Date"
                  ? new Date(this.state.profile[field.name] as string)
                  : null
              }
              onChange={(date: Date) =>
                this.changeFormValue(field.name, date, field.field_type)
              }
              disabled={!field.is_enable}
              required={field.is_required}
            />
          </div>
        </div>
      );
    }

    if (field.field_type === "checkbox") {
      return (
        <div key={index + "renderField"}>
          <Checkbox
            data-test-id={field.name}
            disabled={!field.is_enable}
            checked={this.state.profile[field.name] as boolean}
            onChange={(event) =>
              this.changeFormValue(
                field.name,
                event.target.checked,
                field.field_type
              )
            }
            color="primary"
          />
          {field.title}
        </div>
      );
    }
    if (field.field_type === "boolean") {
      return (
        <div key={index + "renderField"}>
          <FormControl>
            <FormLabel>{field.title}</FormLabel>
            <RadioGroup
              row
              data-test-id={field.name}
              onChange={(event) => {
                this.changeFormValue(
                  field.name,
                  event.target.value,
                  field.field_type
                );
              }}
              value={
                this.state.profile[field.name] === undefined
                  ? undefined
                  : String(this.state.profile[field.name])
              }
            >
              <FormControlLabel
                value={"true"}
                control={<Radio />}
                label="True"
              />
              <FormControlLabel
                value={"false"}
                control={<Radio />}
                label="False"
              />
            </RadioGroup>
          </FormControl>
        </div>
      );
    }
  };

  render() {
    // Customizable Area Start
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          {/* Customizable Area Start */}
          <Snackbar
            open={this.state.showToast}
            autoHideDuration={6000}
            onClose={this.handleCloseToast}
          >
            <Alert onClose={this.handleCloseToast} severity={this.state.toastSeverity}>
              {this.state.toastMessage}
            </Alert>
          </Snackbar>
          <Box sx={{ display: "flex", backgroundColor: "#292524", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "20px", marginTop: 5 }}>
            <div
              onClick={() => {
                const fileInput = document.getElementById("myfileid");
                if (fileInput) {
                  fileInput.click();
                }
              }}
              style={{ cursor: "pointer" }}
              data-test-id="image-upload"
            >
              <img src={this.state.profilePicture} alt="Profile" style={webStyles.profileImage} />
            </div>

            <input
              id="myfileid"
              type="file"
              name="something"
              style={{ display: "none" }}
              accept="image/*"
              onChange={(e) => this.updateProfilePic(e.target.files)}
              data-test-id="upload-btn"
            />
            <div style={{
              display: 'flex', flexDirection: "column",
              justifyContent: "space-evenly", alignItems: "center"
            }}>
              <Typography variant='subtitle2' style={{
                fontSize: '14px',
                fontWeight: 700,
                lineHeight: '17.78px',
                color: "white",
              }}>Jenny Wilson</Typography>
              <Typography variant='body2' style={{ color: "white", }}>jenny.wilson@email.com</Typography>
            </div>
            <Formik
              initialValues={{ firstName: "", lastName: "", phone: "", email: "", address: "", state: "", zipcode: "", instaLink: "", fbLink: "", }}
              validationSchema={Yup.object().shape({
                firstName: Yup.string(),
                lastName: Yup.string(),
                phone: Yup.string()
                  .matches(/^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/, {
                    message: "Invalid phone number",
                    excludeEmptyString: false,
                  }),
                address: Yup.string(),
                zipcode: Yup.string(),
                state: Yup.string(),
              })}
              validateOnChange={true}
              validateOnMount={true}
              data-testid="profileForm"
              onSubmit={(values, { resetForm }) => {
                this.saveProfile(values);
                resetForm();
              }}
            >
              {({
                handleSubmit,
                handleChange,
                touched,
                errors,
                values
              }) => (
                <>
                  <Box
                    flexDirection={'column'}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'flex-start'}
                    width={'65%'}
                  >
                    <InputLabel sx={{ color: "#ffffff", fontSize: "16px", fontWeight: "700", marginTop: '5px' }}>First Name</InputLabel>
                    <OutlinedInput
                      style={webStyles.inputLabel}
                      fullWidth
                      type={'text'}
                      id="outlined-adornment-email"
                      value={values.firstName}
                      placeholder={"First Name"}
                      data-test-id='firstNameInput'
                      onChange={handleChange("firstName")}
                    />

                    <InputLabel sx={{ color: "#ffffff", fontSize: "16px", fontWeight: "700", }}>Last Name</InputLabel>
                    <OutlinedInput
                      style={webStyles.inputLabel}
                      fullWidth
                      type={'text'}
                      id="outlined-adornment-email"
                      value={values.lastName}
                      placeholder={"Last Name"}
                      data-test-id='lastNameInput'
                      onChange={handleChange("lastName")}
                    />

                    <InputLabel sx={{ color: "#ffffff", fontSize: "16px", fontWeight: "700", }}>Phone Number</InputLabel>
                    <div style={{ display: "flex", width: "100%", gap: "10px" }}>
                      <FormControl style={webStyles.inputLabel}>
                        <CountryCodeSelect
                          value={this.state.countryCodeSelected}
                          name="countryCodeSelected"
                          displayEmpty
                          onChange={this.handleCountryCodeChange}
                          data-test-id="phoneNumberCountryCode"
                          inputProps={{ 'aria-label': 'Country code' }}
                          sx={{
                            "& .MuiSelect-select": {
                              display: 'flex',
                              alignItems: 'center',
                              width: '50px',
                            },
                          }}
                        >
                          <MenuItem value="+1">+123</MenuItem>
                          <MenuItem value="+44">+44</MenuItem>
                          <MenuItem value="+91">+91</MenuItem>
                        </CountryCodeSelect>
                      </FormControl>
                      <OutlinedInput
                        style={webStyles.inputLabel}
                        fullWidth
                        type={'text'}
                        id="outlined-adornment-phoneNumber"
                        value={values.phone}
                        placeholder="Your Phone Number"
                        data-test-id='phoneNumberInput'
                        onChange={handleChange("phone")}
                      />
                    </div>
                    {errors.phone && touched.phone ? (
                      <span style={webStyles.errorStyle}>
                        {errors.phone}
                      </span>
                    ) : null}

                    <InputLabel sx={{ fontWeight: "700", color: "#ffffff", fontSize: "16px" }}>Address</InputLabel>
                    <OutlinedInput
                      style={webStyles.inputLabel}
                      fullWidth
                      type={'text'}
                      id="outlined-adornment-email"
                      value={values.address}
                      placeholder={"Address"}
                      data-test-id='addressInput'
                      onChange={handleChange("address")}
                    />

                    <InputLabel sx={{ fontWeight: "700", color: "#ffffff", fontSize: "16px" }}>State</InputLabel>
                    <OutlinedInput
                      style={webStyles.inputLabel}
                      fullWidth
                      type={'text'}
                      id="outlined-adornment-email"
                      value={values.state}
                      placeholder={"State"}
                      data-test-id='stateInput'
                      onChange={handleChange("state")}
                    />

                    <InputLabel sx={{ fontWeight: "700", color: "#ffffff", fontSize: "16px" }}>Zip Code</InputLabel>
                    <OutlinedInput
                      style={webStyles.inputLabel}
                      fullWidth
                      type={'text'}
                      id="outlined-adornment-email"
                      value={values.zipcode}
                      placeholder={"zipcode"}
                      data-test-id='zipcodeInput'
                      onChange={handleChange("zipcode")}
                    />

                    <InputLabel sx={{ fontWeight: "700", color: "#ffffff", fontSize: "16px" }}>Instagram Link (optional)</InputLabel>
                    <OutlinedInput
                      style={webStyles.inputLabel}
                      fullWidth
                      type={'text'}
                      id="outlined-adornment-email"
                      value={values.instaLink}
                      placeholder={"Insta Link"}
                      data-test-id='instaLinkInput'
                      onChange={handleChange("instaLink")}
                    />

                    <InputLabel sx={{ fontWeight: "700", fontSize: "16px", color: "#ffffff" }}>Facebook Link (optional)</InputLabel>
                    <OutlinedInput
                      style={webStyles.inputLabel}
                      fullWidth
                      id="outlined-adornment-email"
                      placeholder={"Facebool link"}
                      type={'text'}
                      value={values.fbLink}
                      data-test-id='fbLinkInput'
                      onChange={handleChange("fbLink")}
                    />
                  </Box>

                  <Button
                    variant="outlined"
                    style={webStyles.btn}
                    color="secondary"
                    onClick={() => handleSubmit()}
                    data-test-id={"submitBtn"}
                  >
                    Save changes
                  </Button>

                  <Button
                    color="secondary"
                    style={webStyles.btn1}
                    onClick={() => this.props.history.push('/settings')}
                    variant="outlined"
                    data-test-id={"backBtn"}
                  >
                    Back to settings
                  </Button>
                </>
              )}
            </Formik>
          </Box>

          {/* Customizable End Start */}
        </ThemeProvider>
      </StyledEngineProvider>
      // Customizable Area End
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles: { [key: string]: React.CSSProperties } = {
  profileImage: {
    width: "80px",
    height: "80px",
    borderRadius: "50%",
    objectFit: "cover",
  },
  btn: {
    backgroundColor: "#C2B30A",
    color: "#ffffff",
    padding: "15px",
    borderRadius: "10px",
    width: "30%",
  },
  inputLabel: {
    color: "#A8A29E",
    borderRadius: 8,
    backgroundColor: "#57534E",
    paddingBottom: "3px",
    marginBottom: "15px"
  },
  errorStyle: {
    color: "red",
    textAlign: "center" as const,
    fontSize: 12
  },
  mainWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    backgroundColor: "#ffff",
    width: "50%",
    fontFamily: "Roboto, sans-serif"
  },
  btn1: {
    color: "#ffffff",
    padding: "15px",
    borderRadius: "10px",
    width: "30%",
    border: "1px solid white"
  },
  bodyText: {
    fontSize: 20,
    paddingTop: 10,
    paddingBottom: 10,
    fontWeight: "400",
  },
  titleText: {
    fontSize: 30,
    paddingTop: 10,
    paddingBottom: 10,
    fontWeight: "600",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    backgroundColor: "rgb(98, 0, 238)",
  },
  inputStyle: {
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
};

// Customizable Area End
