import { BlockComponent } from "../../../framework/src/BlockComponent";

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    history: { push: any };

}

interface S {
    tab: string;
}

interface SS {
    id: any;
}

export default class SettingsController extends BlockComponent<
    Props,
    S,
    SS
> {

    constructor(props: Props) {
        super(props);
        this.state = { tab: "1" }
    }

    handleChange = (event: any, newValue: string) => {
        this.setState({ tab: newValue });
    };

    saveProfile = (values: any) => {
        console.log(values)
    }

}
