import { BlockComponent } from "../../../framework/src/BlockComponent";
export const configJSON = require("./config");
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { AlertColor } from "@mui/material";

export interface Props {
    navigation: any;
    id: string;
    history: { push: any };
}

let otpt = "";

interface S {
    step: string;
    otpRefs: any;
    currentEmail: string;
    showToast: boolean;
    toastMessage: string;
    otp: [string, string, string, string],
    toastSeverity: AlertColor;
    newEmail: string;
}


interface SS {
}

export default class ChangeEmailController extends BlockComponent<
    Props,
    S,
    SS
> {
    apiChangeEmail: any = "";
    resendEmailCallId: any = "";
    apiVerifyOtpCallId: any = "";
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];
        this.state = {
            step: "one",
            otpRefs: [],
            currentEmail: "",
            showToast: false,
            toastMessage: "",
            otp: ["-", "-", "-", "-"],
            toastSeverity: 'success' as AlertColor,
            newEmail: "",
        }

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    }

    async componentDidMount() {

        super.componentDidMount();
        this.send(new Message(getName(MessageEnum.RequestUserCredentials)));

        const userString = localStorage.getItem('user');

        if (userString) {
            let user: any = JSON.parse(userString);
            if (user && user.data) {
                this.setState({
                    currentEmail: user.data.attributes.email
                });
            }
        }
    }

    async receive(from: string, message: Message) {

        if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
            let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))

            if (apiRequestCallId == this.apiChangeEmail) {
                if (responseJson?.errors?.length) {
                    if (responseJson.errors[0].email) {
                        this.handleShowToast(responseJson.errors[0].email, "error");
                    } else {
                        this.handleShowToast(responseJson.errors[0].otp, "error");
                    }
                } else if (responseJson?.meta?.token) {
                    otpt = responseJson.meta.token;
                    this.setState({ step: "three" });
                }
            }

            else if (apiRequestCallId == this.apiVerifyOtpCallId) {
                this.handleOtpVerifyResponse(responseJson);
            }
        }

    }

    handleOtpVerifyResponse(responseJson: any) {
        if (responseJson?.messages) {
            this.onVerifyOtpSuccess(responseJson);
        } else {
            this.onVerifyOtpFail(responseJson);
        }
    }

    onVerifyOtpSuccess(responseJson: any) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        this.saveVerifyOtp(responseJson);
        const userString = localStorage.getItem('user');
        this.handleShowToast("OTP Verified", "success");
        this.props.history.push('/');
        if (userString) {
            const user = JSON.parse(userString);
            user.data.attributes.email = this.state.newEmail
            localStorage.setItem('user', JSON.stringify(user));
        }
    }

    onVerifyOtpFail(responseJson: any) {
        this.handleShowToast(responseJson.errors[0].otp, "error");
        this.parseApiErrorResponse(responseJson);
        this.sendSignupFailMessage();
    }

    sendSignupFailMessage() {
        const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
        this.send(msg);
    }

    saveVerifyOtp(responseJson: any) {
        if (responseJson) {
            const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

            msg.addData(
                getName(MessageEnum.SessionResponseData),
                JSON.stringify(responseJson)
            );

            this.send(msg);

            this.setState({
                otp: ["-", "-", "-", "-"],
                step: "onertyu"
            });
        }
    }

    changeEmail = () => {
        const token: any = localStorage.getItem('authToken');
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": token
        };
        let body = {
            data: {
                attributes: {
                    email: this.state.newEmail
                }
            }
        };


        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiChangeEmail = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.changeEmailUrl
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    };

    handleShowToast = (message: string, severity: AlertColor) => {
        this.setState({ showToast: true, toastMessage: message, toastSeverity: severity });
    }

    handleCloseToast = () => {
        this.setState({ showToast: false });
    }

    handleOTPChange = (index: any, value: any) => {
        const newOtp = [...this.state.otp] as [string, string, string, string];
        if (/^\d$/.test(value)) {
            newOtp[index] = value;
        } else if (value === "") {
            newOtp[index] = "-";
        }
        this.setState({ otp: newOtp });

        if (value !== "" && index < this.state.otp.length - 1) {
            const nextInput = document.getElementById(`otp-input-${index + 1}`);
            if (nextInput) {
                nextInput.focus();
            }
        }
    };

    verifyOTP = () => {
        const otpString = this.state.otp.join("");
        const header = {
            "Content-Type": configJSON.validationApiContentType,
        };
        const data = {
            otp_code: otpString,
            token: otpt
        }
        const httpBody = {
            data: data,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiVerifyOtpCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.OtpVerifyAPiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    handleKeyDown = (index: any, event: any) => {
        if (event.key === "Backspace" && this.state.otp[index] === "-") {
            const prevInput = document.getElementById(`otp-input-${index - 1}`);
            if (prevInput) {
                prevInput.focus();
            }
        }
    };


    goToNextStep = (step: string) => {
        this.setState({
            step: step
        })
    }

    handleChange1 = (event: any, setFieldValue: any) => {
        const { value } = event.target;
        const newEmailValue = event.target.value;
        setFieldValue("newEmail", value);
        this.setState({ newEmail: newEmailValue });
    };
}
