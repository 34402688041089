Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.deleteApiMethod = "DELETE";
exports.productAPiEndPoint = "catalogue/catalogues";
exports.apiMethodTypePost = "POST";
exports.createApiEndPoint ="bx_block_catalogue/catalogues"
exports.getCategoryList = "/bx_block_categories/categories"
exports.getDishesList = "/bx_block_catalogue/chef_dishes"
exports.editApiEndPoint="bx_block_catalogue/catalogues"
exports.imageApiEndPoint="bx_block_catalogue/catalogues"
exports.apiMethodTypePatch = "PATCH"
exports.deleteItemsApiEndpoint = '/bx_block_catalogue/catalogues'
// Customizable Area End