import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { createRef } from 'react';
type Library = "core" | "maps" | "places" | "marker";
const libraries: Library[] = ['places'];


// Customizable Area End
import { AlertColor } from "@mui/material";

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    additionalInfo: string,
    address: string,
    loadError: boolean,
    isLoaded: boolean,
    marker: google.maps.Marker | null,
    map: google.maps.Map | null,
    autocomplete: google.maps.places.Autocomplete | null,
    toastMessage: string,
    toastSeverity: AlertColor;
    showToast: boolean,
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class UserLocationController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    autocompleteRef: React.RefObject<google.maps.places.Autocomplete>;
    mapRef: React.RefObject<google.maps.Map>;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        // Customizable Area Start
        this.state = {
            additionalInfo: "",
            address: "",
            map: null,
            isLoaded: false,
            autocomplete: null,
            marker: null,
            showToast: false,
            loadError: false,
            toastSeverity: 'success' as AlertColor,
            toastMessage: '',
        };

        this.autocompleteRef = createRef<google.maps.places.Autocomplete>();
        this.mapRef = createRef<google.maps.Map>();
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);

        // Customizable Area End
    }

    // Customizable Area Start

    handleShowToast = (message: string, severity: AlertColor) => {
        this.setState({ showToast: true, toastMessage: message, toastSeverity: severity });
    }
    handleCloseToast = () => {
        this.setState({ showToast: false });
    }
    handleSubmit = () => {
        const { address } = this.state;
        if (!address || address.trim() === '') {
            this.handleShowToast("Please enter a valid address", "error");
            return;
        }
        this.handleShowToast("Location saved successfully!", "success");
        this.setState({
            address: '',
            additionalInfo: '',
        });
    };
    onLoadMap = (map: google.maps.Map) => {
        this.setState({ map });
    };
    async componentDidMount() {
        const MapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
        const element = document.createElement('script');
        element.src = `https://maps.googleapis.com/maps/api/js?key=${MapsApiKey}&libraries=${libraries.join(',')}`;
        element.async = true;
        element.onload = () => {
            this.setState({ isLoaded: true });
        };
        element.onerror = () => {
            this.setState({ loadError: true, isLoaded: false });
        };
        document.head.appendChild(element);
    }
    handleInputChange = (fieldName: keyof S) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        this.setState(prevState => ({
            ...prevState,
            [fieldName]: value as S[typeof fieldName],
        }));
    }
    onLoadAutocomplete = (autocomplete: google.maps.places.Autocomplete) => {
        this.setState({ autocomplete });
    }; 
    handleUserPlaceChanged = () => {
        const userplace = this.state.autocomplete?.getPlace();
        if (userplace && userplace.geometry) {
            const location = userplace.geometry.location;
            if (location) {
                this.setState({
                    address: userplace.formatted_address || '',
                });
                this.state.map?.setCenter(location);
                this.state.map?.setZoom(15);
                if (this.state.marker) {
                    this.state.marker.setPosition(location);
                } else {
                    const usermarker = new window.google.maps.Marker({
                        position: location,
                        map: this.state.map
                    });
                    this.setState({ marker: usermarker });
                }
            }
        }
    };   
    // Customizable Area End
}
