import React from "react";

// Customizable Area Start
import { Typography, Button, Link } from "@mui/material";
import { image1, image2, image3 } from "./assets";

import { customerIcon, bakerIcon } from "./assets";
// Customizable Area End

import LoginController, {
    Props,
} from "./LoginController";

export default class RolesLogin extends LoginController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End
    render() {
        return (
            // Customizable Area Start
            // Required for all blocks
            <div style={styles.main}>
                <div style={styles.wrapper}>
                    <img style={styles.banner} src={require("../assets/banner.png")} />
                    <div style={{
                        content: '',
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        background: "linear-gradient(to right, rgba(0,0,0,0) 94%, rgb(0, 0, 0) 100%)",
                        zIndex: 1
                    }}></div>
                </div>
                <div style={styles.body}>
                    <div style={{ display: 'flex', flexDirection: "column" as const, alignItems: 'center', marginBottom: '24px', marginTop: '20px' }}>
                        <img width={71} height={50} src={require("../../../components/src/logo.svg")} />
                        <Typography variant="h5" style={{ color: "#C2B30A", fontWeight: "700", textTransform: "uppercase", marginTop: '8px', letterSpacing: "3px" }}>
                            AMATEUR BAKERS
                        </Typography>
                        <Typography variant="caption" style={{ fontSize: "8px", color: "#C2B30A", fontWeight: "200", textTransform: "uppercase", marginTop: '4px', letterSpacing: "3px" }}>
                            from our oven to your table
                        </Typography>
                    </div>


                    <div style={styles.image1}>
                    <img src={image1} alt="image1"/>
                </div>
                <div style={styles.image2}>
                    <img src={image2} alt="image2"/>
                </div>
                <div style={styles.image3}>
                    <img src={image3} alt="image3"/>
                </div>

                    <div style={{ width: "40%" }}>
                        <div
                            style={styles.buttonsWrapper}
                        >
                            <Typography variant="h4" style={{ color: "#C2B30A", fontWeight: "700", textAlign: "center" }}>
                                Login
                            </Typography>
                            <Button
                                fullWidth
                                variant="contained"
                                size="large"
                                data-test-id={"btnBaker"}
                                onClick={this.goToLogin}
                                style={styles.button}
                            >
                                <img style={{marginLeft: "23%", marginRight: "10%"}} src={bakerIcon} />
                                <span style={{  textTransform: "none" }}> I'm a Baker</span>
                            </Button>
                        </div>
                        <div
                            style={styles.buttonsWrapper}
                        >
                            <Button
                                fullWidth
                                variant="contained"
                                size="large"
                                data-test-id={"btnCustomer"}
                                onClick={this.goToUserLogin}
                                style={styles.signupbutton}
                            >
                                <img style={{marginLeft: "23%", marginRight: "10%"}}  src={customerIcon} />
                                <span style={{  textTransform: "none" }}>

                                    I'm Customer
                                </span>
                            </Button>
                        </div>
                    </div>
                    <div
                        style={styles.notHaveAccount}
                    >
                        <Typography variant="body1" color={"#ffffff"} >
                            Don't have an account yet?
                        </Typography>
                        <Link variant="caption" data-test-id="createOne" style={styles.forgotPassword} color="inherit" onClick={this.goToUserSignup}>
                            Create one
                        </Link>
                    </div>
                    <div
                        style={styles.arrowWrapper}
                        data-test-id="backBtn"
                        onClick={this.goBackToInitial}
                    >
                        <img
                            src={require('../assets/back_arrow.png')}
                            style={styles.backArrow}
                        />
                        <Typography style={styles.noAccountLabel} >
                            Back
                        </Typography>
                    </div>
                </div>
            </div>
            // Customizable Area End
        );
    }

}
// Customizable Area Start
const styles: { [key: string]: React.CSSProperties } = {
    main: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#000000",
        fontSize: 25,
    },
    wrapper: {
        flex: 1,
        position: "relative",
    },
    banner: {
        width: "100%", height: "100vh"
    },
    gradient: {
        width: '100%',
        height: '100%',
    },
    body: {
        flex: 1,
        display: "flex",
        flexDirection: "column" as const,
        alignItems: "center",
        justifyContent: "center",
        padding: "10px",
        width: "40%",
        textTransform: "capitalize"
    },
    buttonsWrapper: {
        flex: 1,
        paddingTop: "0px",
        paddingBottom: "0px",
        width: "100%"
    },
    logo: {
        height: 80,
        width: 80,
        marginTop: "50px",
        marginBottom: "25px",
    },
    arrowWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px"
    },
    button: {
        backgroundColor: "#C2B30A",
        color: "#ffffff",
        padding: "15px",
        fontWeight: "700",
        marginBottom: "20px",
        borderRadius: "10px",
        marginTop:"30px",
        display: "flex",
        justifyContent: "flex-start"
    },
    backArrow: {
        height: 30,
        width: 30,
    },
    noAccountLabel: {
        fontSize: 16,
        color: "#ffffff",
        fontWeight: "700",
        cursor: "pointer"
    },
    notHaveAccount: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#FFFFFF",
        marginTop: "30px",
        textTransform: "none"
    },
    signupbutton: {
        border: "1px solid #C2B30A",
        backgroundColor: "transparent",
        color: "#C2B30A",
        padding: "15px",
        fontWeight: "700",
        borderRadius: "10px",
        display: "flex",
        justifyContent: "flex-start"
    },
    image1: {
        position: "absolute",
        top: 0,
        left: "50%"
    },
    image2: {
        position: "absolute",
        right: 0,
        top: 0
    },
    image3: {
        position: "absolute",
        bottom: 0,
        right: 0,
        zIndex: 0
    },
    forgotPassword: {
        fontWeight: "700",
        fontSize: 13,
        lineHeight: 5,
        textAlign: "left" as const,
        color: "#FFFFFF",
        marginLeft: 5,
        cursor: "pointer"
    },
};
// Customizable Area End