import React from "react";

// Customizable Area Start
import { image1, image2, image3 } from "./assets";


import { Typography, Button, Snackbar, Alert } from "@mui/material";


// Customizable Area End

import EmailAccountUserRegistrationController, {
    Props
} from "./EmailAccountUserRegistrationController.web";

export default class SuccessAccount extends EmailAccountUserRegistrationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <div style={styles.main}>
                <Snackbar
                    autoHideDuration={6000}
                    open={this.state.showToast}
                    data-test-id="btnClose"
                    onClose={this.handleCloseToast}
                >
                    <Alert severity={this.state.toastSeverity} onClose={this.handleCloseToast} >
                        {this.state.toastMessage}
                    </Alert>
                </Snackbar>
                <div style={styles.wrapper}>
                    <img src={require("../../email-account-login/assets/banner.png")} style={styles.banner}  />
                    <div style={{
                        position: "absolute",
                        content: '',
                        left: 0,
                        top: 0,
                        bottom: 0,
                        right: 0,
                        zIndex: 1,
                        background: "linear-gradient(to right, rgba(0,0,0,0) 94%, rgb(0, 0, 0) 100%)",
                    }}></div>
                </div>

                <div style={styles.image1}>
                    <img src={image1} alt="image1"/>
                </div>
                <div style={styles.image2}>
                    <img src={image2} alt="image2"/>
                </div>
                <div style={styles.image3}>
                    <img src={image3} alt="image3"/>
                </div>
                <div style={styles.body}>
                    <div style={{  marginBottom: '24px', marginTop: '20px', display: 'flex', flexDirection: "column" as const, alignItems: 'center', }}>
                        <img width={71} src={require("../../../components/src/logo.svg")}  height={50}/>
                        <Typography variant="h5" style={{ marginTop: '8px', letterSpacing: "3px", color: "#C2B30A", fontWeight: "700", }}>
                            AMATEUR BAKERS
                        </Typography>
                        <Typography style={{ color: "#C2B30A", fontWeight: "200", marginTop: '4px', letterSpacing: "3px" }} variant="caption">
                            from our oven to your table
                        </Typography>
                        <Typography style={{ marginTop: "50px", marginBottom: "50px", color: "#C2B30A", fontWeight: "700", letterSpacing: "3px", textAlign: "center" }} variant="h3" >
                            <span style={{ color: "white" }}>Your </span>Account  <span style={{ color: "white" }}> is <br />Ready!!! </span>
                        </Typography>
                        <Typography variant="h6" style={{ fontSize: "12px", textAlign: "center", marginTop: "20px", color: "white", fontWeight: "700", letterSpacing: "3px" }}>
                            Welcome!!! You are ready to give your best and make<br /> your customers happy.
                        </Typography>

                        <div style={styles.buttonsWrapper}>
                            <Button
                                fullWidth
                                variant="contained"
                                size="large"
                                data-test-id="resendEmail"
                                onClick={this.goToLogin}
                                style={styles.button}
                            >
                                Go to Login
                            </Button>
                        </div>
                    </div>
                </div>
            </div>

            // Customizable Area End
        );
    }
}

// Customizable Area Start
const styles: { [key: string]: React.CSSProperties } = {
    formWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column" as const,
        width: "100%",
        paddingTop: "20px"
    },
    inputWrapper: {
        flex: 1,
        margin: "20px",
        width: "100%"
    },
    textFont: {
        color: "#55D9B4",
        fontFamily: "Cerebri Sans",
        fontSize: 32,
        fontWeight: "700",
        textAlign: "left",
        lineHeight: 3,
    },
    forgotPassLabel: { display: "flex", 
    justifyContent: "center", alignItems: "center"
 },
    buttonsWrapper: {
        flex: 1,
        padding: "20px",
        paddingTop: "0px",
        paddingBottom: "0px",
        width: "100%",
        marginTop: "20px"
    },
    checkboxWrapper: {
        marginTop: '10px',
        marginBottom: '20px',
        display: 'flex',
        flexDirection: "column",
        alignItems: 'center',
    },
    paddingBottom: {
        paddingBottom: "0px"
    },
    removeBackground: {
        backgroundColor: "transparent",
        height: "10px"
    },
    checkBoxText: {
        fontSize: 18,
        color: "#6200EE",
        fontWeight: "bold",
    },
  
    main: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#000000",
        fontSize: 25,
    },
   
    activeStep: {
        backgroundColor: '#f5c800',
    },
    checkboxLabel: {
        color: '#ffffff',
        fontSize: 14,
        marginLeft: '10px',
    },
    input: {
        borderRadius: 8,
        backgroundColor: "#57534E",
        borderColor: "#000000",
    },
    inputLabel: {
        color: "#FFFFFF",
        padding: "5px 15px",
        borderRadius: 8,
        backgroundColor: "#57534E",
    },

    Line: {
        width: '50px',
        height: '2px',
        backgroundColor: '#444',
        margin: '0 10px',
    },

    body: {
        flex: 1,
        display: "flex",
        flexDirection: "column" as const,
        alignItems: "center",
        justifyContent: "center",
        padding: "10px",
        width: "40%",
    },
    eyeIcon: {
        height: "25px",
        width: "25px"
    },

    ProgressIndicator: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '2rem',
    },
    image1: {
        position: "absolute",
        top: 0,
        left: "50%"
    },
    image2: {
        position: "absolute",
        right: 0,
        top: 0
    },
    image3: {
        position: "absolute",
        bottom: 0,
        right: 0,
        zIndex: 0
    },

    Step: {
        width: '15px',
        height: '15px',
        backgroundColor: '#444',
        borderRadius: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 1,
        color: '#fff',
    },

   
    wrapper: {
        flex: 1,
        position: "relative",
    },
    banner: {
        width: "100%", height: "100vh"
    },
    gradient: {
        width: '100%',
        height: '100%',
    },
   
    notHaveAccount: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#FFFFFF"
    },
    logo: {
        height: 80,
        width: 80,
        marginTop: "50px",
        marginBottom: "25px",
    },
    backArrow: {
        height: 30,
        width: 30,
    },
    arrowWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px"
    },
    button: {
        backgroundColor: "#C2B30A",
        color: "#ffffff",
        padding: "15px",
        fontWeight: "700",
        borderRadius: "10px",
        textTransform: "none"
    },
    forgotPassword: {
        fontWeight: "700",
        fontSize: 13,
        lineHeight: 5,
        textAlign: "left" as const,
        color: "#FFFFFF",
        marginLeft: 5,
    },
    noAccountLabel: {
        fontSize: 16,
        color: "#ffffff",
        fontWeight: "700"
    },
    errorStyle: {
        color: "red",
        textAlign: "center" as const,
        fontSize: 12
    },
};
// Customizable Area End
