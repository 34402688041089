import { BlockComponent } from "../../../framework/src/BlockComponent";
export const configJSON = require("./config");
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { AlertColor } from "@mui/material";
export interface Props {
    history: { push: any };
}

interface S {
    otpRefs: any;
    step: string;
    showToast: boolean;
    toastMessage:string;
    open: boolean,
    password: string,

}

interface SS {
}

export default class ChangePasswordController extends BlockComponent<
    Props,
    S,
    SS
> {
    apiChangePassAccountId: string = "";
    constructor(props: Props) {
        super(props);
        this.state = {
            step: "one",
            otpRefs: [],
            showToast: false,
            toastMessage:"",
            open: false,
            password: "",

        }
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {

        if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
            let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))

            if (apiRequestCallId == this.apiChangePassAccountId) {
                if (responseJson.message == "Password updated successfully") {
                    this.handleShowToast(responseJson.message, "success");
                    this.handleOpen()
                    // this.props.history.push("/")
                }
            }
        }

    }

    handleShowToast = (message: string, severity: AlertColor) => {
        this.setState({ showToast: true, toastMessage: message });
    }

    handleCloseToast = () => {
        this.setState({ showToast: false });
    }
    handleOpen = () => {
        this.setState({
            open: true,
        });
    }
    handleClose = () => {
    localStorage.clear();
        this.setState({
            open: false,
        }, () => this.props.history.push("/"));
    }

    changePassword = (values: any) => {

        const token: any = localStorage.getItem('authToken');
        let formData = new FormData()
        formData.append("current_password", values.currentPassword);
        formData.append("new_password", values.newPassword);

        let body = formData;

        const header = {
            "Accept": "*/*",
            "token": token
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiChangePassAccountId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.changePassUrl
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            body
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.methodTypePatch
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

}