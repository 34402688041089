import React from "react";
import ChangeEmailController, {
    Props,
} from "./ChangeEmailController.web";
import OutlinedInput from '@mui/material/OutlinedInput';

import { Formik } from "formik";
import * as Yup from "yup";
import { Box, Button, Typography, InputLabel, Link, Snackbar, Alert } from "@mui/material";

export default class ChangeEmail extends ChangeEmailController {
    constructor(props: Props) {
        super(props);
    }

    render() {

        return (
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "20px", marginTop: 5 }}>
                <Snackbar
                    open={this.state.showToast}

                    data-test-id="btnClose"
                    autoHideDuration={6000}
                    onClose={this.handleCloseToast}
                >
                    <Alert severity={this.state.toastSeverity} onClose={this.handleCloseToast} >
                        {this.state.toastMessage}
                    </Alert>
                </Snackbar>
                {this.state.step == "one" &&
                    <Formik
                        initialValues={{ email: this.state.currentEmail }}
                        validateOnMount={true}
                        validateOnChange={true}
                        onSubmit={() => {
                            this.goToNextStep('two');
                        }}
                        data-testid="emailForm"
                    >
                        {({
                            handleChange,
                            handleSubmit,
                            errors,
                            touched,
                            values
                        }) => (
                            <>
                                <Box
                                    display={'flex'}
                                    flexDirection={'column'}
                                    alignItems={'flex-start'}
                                    justifyContent={'center'}
                                    width={'65%'}
                                >
                                    <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}>Your Current Email</InputLabel>
                                    <OutlinedInput
                                        fullWidth
                                        style={webStyles.inputLabel}
                                        id="outlined-adornment-email"
                                        type={'text'}
                                        placeholder={"New Email"}
                                        value={this.state.currentEmail}
                                        onChange={handleChange("email")}
                                        data-test-id='emailInput'
                                    />
                                    {errors.email && touched.email ? (
                                        <span style={webStyles.errorStyle}>
                                            {errors.email}
                                        </span>
                                    ) : null}
                                </Box>
                                <Button
                                    data-test-id={"changeEmailBtn"}
                                    style={webStyles.button}
                                    color="secondary"
                                    variant="outlined"
                                    onClick={() => handleSubmit()}
                                >
                                    Change Email
                                </Button>
                            </>
                        )}
                    </Formik>}

                {this.state.step == "two" && <Formik
                    initialValues={{ newEmail: "" }}
                    validationSchema={Yup.object().shape({
                        newEmail: Yup.string().email().required("New Email is required"),
                    })}
                    validateOnMount={true}
                    validateOnChange={true}
                    onSubmit={() => {
                        this.changeEmail();
                    }}
                    data-testid="continueForm"
                >
                    {({
                        handleSubmit,
                        setFieldValue,
                        errors,
                        touched,
                        values
                    }) => (
                        <>
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                justifyContent={'center'}
                                alignItems={'flex-start'}
                                width={'65%'}
                            >
                                <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}>Enter your new Email</InputLabel>
                                <OutlinedInput
                                    fullWidth
                                    style={webStyles.inputLabel}
                                    placeholder={"email@email.com"}
                                    id="outlined-email"
                                    type={'text'}
                                    value={values.newEmail}
                                    data-test-id='newemailInput'
                                    onChange={(event) => this.handleChange1(event, setFieldValue)}
                                />
                                {errors.newEmail && touched.newEmail ? (
                                    <span data-testid="emailError" style={webStyles.errorStyle}>
                                        {errors.newEmail}
                                    </span>
                                ) : null}
                            </Box>
                            <Button
                                data-test-id={"continueButton"}
                                style={webStyles.button}
                                color="secondary"
                                variant="outlined"
                                onClick={() => handleSubmit()}
                            >
                                Continue
                            </Button>
                        </>
                    )}
                </Formik>}

                {this.state.step === "three" && (
                    <Formik
                        initialValues={{ otp: "" }}
                        validateOnMount={true}
                        validateOnChange={true}
                        onSubmit={() => {
                            this.verifyOTP();
                        }}
                        data-testid="otpForm"
                    >
                        {({
                            handleSubmit,
                        }) => (
                            <>
                                <Typography
                                    variant="subtitle1"
                                    sx={{ textAlign: "center" }}
                                >
                                    <span style={{ color: "#ffffff" }}>Please&nbsp;</span>
                                    <span style={{ color: "#C2B30A", fontWeight: 700 }}>ENTER CODE</span>
                                    <span style={{ color: "#ffffff" }}> we sent you to your Email</span>
                                </Typography>

                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center"
                                    justifyContent="center"
                                    gap={3}
                                >
                                    <div style={webStyles.otpContainerStyle}>
                                        {this.state.otp.map((value: string, index: number) => (
                                            <input
                                                key={index}
                                                id={`otp-input-${index}`}
                                                value={value === "-" ? "" : value}
                                                onChange={(event) => this.handleOTPChange(index, event.target.value)}
                                                onKeyDown={(e) => this.handleKeyDown(index, e)}
                                                maxLength={1}
                                                style={{
                                                    ...webStyles.otpInputStyle,
                                                    borderColor: value !== "-" ? "#C2B30A" : "#57534E",
                                                }}
                                                data-test-id="otpInput"
                                                placeholder="-"
                                            />
                                        ))}
                                    </div>

                                    <div
                                        style={webStyles.notHaveAccount}
                                        data-test-id="resendOtp"
                                        onClick={() => {
                                            this.changeEmail();
                                        }}
                                    >
                                        <Link
                                            variant="caption"
                                            style={webStyles.forgotPassword}
                                            color="inherit"
                                        >
                                            Resend
                                        </Link>
                                    </div>

                                    <Button
                                        fullWidth
                                        data-test-id="verifyBtn"
                                        style={webStyles.button}
                                        color="secondary"
                                        variant="outlined"
                                        onClick={() => handleSubmit()}
                                    >
                                        Verify
                                    </Button>

                                    <Button
                                        fullWidth
                                        data-test-id="backBtn"
                                        style={webStyles.button1}
                                        color="secondary"
                                        variant="outlined"
                                        onClick={() => this.goToNextStep("two")}
                                    >
                                        Back
                                    </Button>
                                </Box>
                            </>
                        )}
                    </Formik>
                )}

            </Box>
        )
    }
}

const webStyles: { [key: string]: React.CSSProperties } = {
    inputLabel: {
        borderRadius: 10,
        color: "#FFFFFF",
        backgroundColor: "#57534E",
        paddingBottom: "3px"
    },
    inputStyle: {
        width: "100%",
        justifyContent: "space-between",
        height: "100px",
        display: "flex",
        flexDirection: "column",
    },
    button: {
        backgroundColor: "#C2B30A",
        color: "#ffffff",
        padding: "15px",
        textTransform: 'none',
        borderRadius: 10,
    },
    button1: {
        backgroundColor: "transparent",
        border: "1px solid white",
        color: "#ffffff",
        padding: "15px",
        textTransform: 'none',
        borderRadius: 10,
    },
    mainWrapper: {
        display: "flex",
        width: "50%",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: "30px",
        backgroundColor: "#ffff",
        fontFamily: "Roboto, sans-serif"
    },
    titleText: {
        fontSize: 30,
        paddingTop: 10,
        paddingBottom: 10,
        fontWeight: 600,
    },
    errorStyle: {
        textAlign: "center" as const,
        color: "red",
        fontSize: 12
    },
    bodyText: {
        fontWeight: 400,
        fontSize: 20,
        paddingTop: 15,
        paddingBottom: 15,
    },
    buttonStyle: {
        width: "100%",
        backgroundColor: "rgb(98, 0, 238)",
        height: "45px",
        marginTop: "40px",
    },
    otpContainerStyle: {
        display: "flex",
        justifyContent: "center",
        marginTop: "20px",
        marginBottom: "20px"
    },

    otpInputStyle: {
        width: "56px",
        height: "56px",
        margin: "0 15px",
        fontSize: "24px",
        textAlign: "center",
        borderRadius: "10px",
        outline: "none",
        transition: "border-color 0.3s",
        backgroundColor: "#57534E",
        color: "white",
    },

    notHaveAccount: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#FFFFFF",
        cursor: "pointer"
    },
};