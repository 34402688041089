import React from "react";

// Customizable Area Start
import { Typography, Button, Link, Snackbar, Alert } from "@mui/material";

import { image1, image2, image3 } from "./assets";

// Customizable Area End

import EmailAccountUserRegistrationController, {
    Props
} from "./EmailAccountUserRegistrationController.web";

export default class ActivateAccount extends EmailAccountUserRegistrationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start





        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <div style={styles.main}  >
                <Snackbar
                    data-test-id="btnClose"
                    onClose={this.handleCloseToast}
                    open={this.state.showToast}
                    autoHideDuration={6000}
                >
                    <Alert onClose={this.handleCloseToast}   severity={this.state.toastSeverity}>
                         {this.state.toastMessage}
                    </Alert>
                </Snackbar>


                <div style={styles.image1}>
                    <img src={image1} alt="image1"/>
                </div>
                <div style={styles.image2}>
                    <img src={image2} alt="image2"/>
                </div>
                <div style={styles.image3}>
                    <img src={image3} alt="image3"/>
                </div>
                <div style={styles.wrapper}>
                    <img style={styles.banner}  src={require("../../email-account-login/assets/banner.png")} />
                    <div style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        background: "linear-gradient(to right, rgba(0,0,0,0) 94%, rgb(0, 0, 0) 100%)",
                        zIndex: 1,
                        content: '',
                    }}>

                    </div>
                </div>
                <div style={styles.body}>
                    <div style={{ display: 'flex', marginBottom: '24px', flexDirection: "column" as const, alignItems: 'center', marginTop: '20px' }}>
                        <img width={71} src={require("../../../components/src/logo.svg")}  height={50} />
                        <Typography variant="h5" style={{ letterSpacing: "3px", color: "#C2B30A", fontWeight: "700", marginTop: '8px',  }}>
                            AMATEUR BAKERS
                        </Typography>
                        <Typography variant="caption" style={{  letterSpacing: "3px", color: "#C2B30A", fontWeight: "200", marginTop: '4px', }}>
                            from our oven to your table
                        </Typography>
                        <Typography variant="h3" style={{ letterSpacing: "3px", marginTop: "50px", marginBottom: "50px", color: "#C2B30A", fontWeight: "700", textAlign: "center" }}>
                            Please <span style={{ color: "white" }}>check your <br />email</span>
                        </Typography>
                        <Typography variant="h6" style={{ marginTop: "20px", color: "white", fontWeight: "700", letterSpacing: "3px" }}>
                            Welcome to AMATEUR BAKERS
                        </Typography>
                        <Typography variant="h6" style={{ marginTop: "20px", marginBottom: "30px", color: "white", fontWeight: "700", letterSpacing: "3px", textAlign: "center" }}>
                            Check your email and follow the instructions to <br />
                            confirm your email to activate your accont.
                        </Typography>
                        <div style={styles.buttonsWrapper}>
                            <Button
                                fullWidth
                                variant="contained"
                                size="large"
                                data-test-id="sendCode"
                                onClick={this.goToVerifyCode}
                                style={styles.button}
                            >
                                Verify Code
                            </Button>
                        </div>
                        <div
                            style={styles.notHaveAccount}
                        >
                            <Typography variant="body1" color={"#ffffff"} >
                                Need help?
                            </Typography>
                            <Link variant="caption" style={styles.forgotPassword} color="inherit">
                                Contact support
                            </Link>
                        </div>

                    </div>


                </div>

            </div>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const styles: { [key: string]: React.CSSProperties } = {
   
    formWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        paddingTop: "20px",
        flexDirection: "column" as const,
    },
    inputWrapper: {
        margin: "20px",
        flex: 1,
        width: "100%"
    },
    forgotPassLabel: { display: "flex", justifyContent: "center", alignItems: "center" },
    checkBoxText: {
        fontSize: 18,
        color: "#6200EE",
        fontWeight: "bold",
    },
    
    removeBackground: {
        height: "10px",
        backgroundColor: "transparent",
    },
    paddingBottom: {
        paddingBottom: "0px",
    },
   
    main: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#000000",
        fontSize: 25,
    },
    checkboxWrapper: {
        marginTop: '10px',
        marginBottom: '20px',
        display: 'flex',
        flexDirection: "column",
        alignItems: 'center',
    },
    checkboxLabel: {
        color: '#ffffff',
        fontSize: 14,
        marginLeft: '10px',
    },
    inputLabel: {
        padding: "5px 15px",
        borderRadius: 8,
        color: "#A8A29E",
        backgroundColor: "#57534E",
    },
    image1: {
        position: "absolute",
        top: 0,
        left: "50%"
    },
    image2: {
        position: "absolute",
        right: 0,
        top: 0
    },
    image3: {
        position: "absolute",
        bottom: 0,
        right: 0,
        zIndex: 0
    },
    textFont: {
        color: "#55D9B4",
        fontFamily: "Cerebri Sans",
        fontWeight: "700",
        lineHeight: 3,
        textAlign: "left",
        fontSize: 32,
    },
    activeStep: {
        backgroundColor: '#f5c800',
    },

    Step: {
        width: '15px',
        height: '15px',
        backgroundColor: '#444',
        borderRadius: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 1,
        color: '#fff',
    },

    eyeIcon: {
        height: "25px",
        width: "25px"
    },
    input: {
        borderRadius: 8,
        backgroundColor: "#57534E",
        borderColor: "#000000",
    },
    buttonsWrapper: {
        flex: 1,
        padding: "20px",
        paddingTop: "0px",
        paddingBottom: "0px",
        width: "100%",
        textTransform: "none"
    },
    notHaveAccount: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#FFFFFF"
    },
    logo: {
        height: 80,
        width: 80,
        marginTop: "50px",
        marginBottom: "25px",
    },
    backArrow: {
        height: 30,
        width: 30,
    },
    arrowWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px"
    },
    button: {
        backgroundColor: "#C2B30A",
        color: "#ffffff",
        padding: "15px",
        fontWeight: "700",
        borderRadius: "10px",
        textTransform: "none"
    },
    forgotPassword: {
        fontWeight: "700",
        fontSize: 13,
        lineHeight: 5,
        textAlign: "left" as const,
        color: "#FFFFFF",
        marginLeft: 5,
    },
    noAccountLabel: {
        fontSize: 16,
        color: "#ffffff",
        fontWeight: "700"
    },
    errorStyle: {
        color: "red",
        textAlign: "center" as const,
        fontSize: 12
    },

    Line: {
        width: '50px',
        height: '2px',
        backgroundColor: '#444',
        margin: '0 10px',
    },

    ProgressIndicator: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '2rem',
    },
    wrapper: {
        flex: 1,
        position: "relative",
    },
    banner: {
        width: "100%", height: "100vh"
    },
    gradient: {
        width: '100%',
        height: '100%',
    },
    body: {
        flex: 1,
        display: "flex",
        flexDirection: "column" as const,
        alignItems: "center",
        justifyContent: "center",
        padding: "10px",
        width: "40%",
    },
   
};
// Customizable Area End
