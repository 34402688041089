import React from "react";
// Customizable Area Start
import { Box, Button, Typography } from "@mui/material";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';

import AcceptOrDeliverModal from "./AcceptOrDeliverModal.web";
import DeclineOrderModal from "./DeclineOrderModal.web";
import OpenBakeryModal from "./OpenBakeryModal.web";

// Customizable Area End
import DashboardController, {
  Props,
  webConfigJSON
} from "./DashboardController.web";

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.openBakeryOpenModal = this.openBakeryOpenModal.bind(this);
    this.closeBakeryOpenModal = this.closeBakeryOpenModal.bind(this);
    this.openStore = this.openStore.bind(this);
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <Box sx={{ width: '100%', height: "100%" }}>
        <Typography variant="h6" sx={{
          fontSize: '28px',
          fontWeight: 700,
        }}>
          <span style={{ color: "#ffffff", marginLeft: "3px" }}>
            Dashboard
          </span>
        </Typography>
        <Tabs
          value={this.state.isStoreOpen ? this.state.tab : null} sx={{
            '& .MuiTabs-indicator': {
              backgroundColor: this.state.tab === 'one' ? '#C2B30A' :
                this.state.tab === 'two' ? '#009168' :
                  '#AE7300',
            }
          }}
          onChange={(e, v) => this.handleChange(e, v)}
          aria-label="secondary tabs example"
          centered
          variant="fullWidth"
          data-test-id="tab"
        >
          <Tab value="one"
            disabled={!this.state.isStoreOpen}
            sx={{
              color: '#ffffff',
              textTransform: 'none',
              fontWeight: '400',
              '&.Mui-disabled': {
                color: '#78716C',
                backgroundColor: '#44403C',
                opacity: 1,
                pointerEvents: 'none',
              },
              '&.Mui-selected': {
                fontWeight: '700',
                color: '#ffffff',
              },
            }} label="To Accept" />
          <Tab value="two"
            disabled={!this.state.isStoreOpen}
            sx={{
              color: '#ffffff',
              fontWeight: '400',
              textTransform: 'none',
              '&.Mui-disabled': {
                pointerEvents: 'none',
                backgroundColor: '#44403C',
                color: '#78716C',
                opacity: 1,
              },
              '&.Mui-selected': {
                fontWeight: '700',
                color: '#ffffff',
              },
            }} label="Preparing" />
          <Tab value="three"
            disabled={!this.state.isStoreOpen}
            sx={{
              color: '#ffffff',
              textTransform: 'none',
              fontWeight: '400',
              '&.Mui-disabled': {
                color: '#78716C',
                pointerEvents: 'none',
                backgroundColor: '#44403C',
                opacity: 1,
              },
              '&.Mui-selected': {
                fontWeight: '700',
                color: '#ffffff',
              },
            }} label="Delivered" />
        </Tabs>
        {this.state.isStoreOpen ?
          (<>
            {this.state.orders?.to_accept?.length === 0 &&
              this.state.orders.preparing.length === 0 &&
              this.state.orders.delivered.length === 0 ? (
              <Typography variant="body1" sx={{ display: 'flex', height: "80%", justifyContent: "center", alignItems: "center", textAlign: 'center' }}>
                Here you will see your new customer orders. Accept them to confirm that <br /> you have started preparing them.
              </Typography>
            ) : (
              <Box sx={{
                p: 3,
                flexDirection: 'column',
                justifyContent: "center",
                alignItems: 'center',
              }}>
                <Grid container spacing={2}>
                  <Grid item xs={4} sm={4}>
                    {this.state.tab === 'one' && this.state.orders?.to_accept?.map(order => (
                      <Card data-test-id="accept-modal" onClick={() => this.openAcceptOrDeliverModal('accept', order.id)} sx={{ display: 'flex', width: "100%", backgroundColor: "#292524", borderColor: "#C2B30A", borderRadius: "8px", color: "#ffffff", marginBottom: "10px" }} variant="outlined">
                        <Box sx={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
                          <CardContent >
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: 'center' }}>
                              <Typography sx={{ fontWeight: 700, fontSize: "14px" }} variant="subtitle1" >
                                Order #{order.id}
                              </Typography>
                              <Chip label="New" sx={{ backgroundColor: "#C2B30A", color: "#ffffff" }} />
                            </Box>
                          </CardContent>
                          <Box sx={{
                            padding: "16px",
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: "space-between",
                            alignItems: 'center',
                          }}>
                            <Box sx={{
                              flexDirection: 'row',
                              display: 'flex',
                              justifyContent: "space-around",
                              alignItems: 'center'
                            }}>
                              <Typography variant="subtitle1" sx={{ color: "#D6D3D1", fontSize: "12px" }} color="text.secondary" component="div">
                                Client: &nbsp;
                              </Typography>
                              <Typography variant="subtitle1"
                                sx={{ fontSize: "12px" }}
                                component="div">
                                James Hetfield
                              </Typography>
                            </Box>
                            <Typography variant="subtitle1" component="div" sx={{ fontSize: "12px" }} >
                              {this.formatTime(order.created_at)}
                            </Typography>
                          </Box>
                        </Box>
                      </Card>
                    ))}

                  </Grid>
                  <Grid item xs={4} sm={4}>
                    {this.state.tab === 'two' && this.state.orders.preparing.map(order => (
                      <Card
                        sx={{ display: 'flex', width: "100%", backgroundColor: "#009168", borderRadius: "8px", color: "#ffffff", marginBottom: "10px" }}
                        data-test-id={'deliver-modal'}
                        variant="outlined"
                        onClick={() => this.openAcceptOrDeliverModal('deliver', order.id)}
                      >
                        <Box sx={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
                          <CardContent >
                            <Typography sx={{ fontSize: "14px", fontWeight: 700 }} variant="subtitle1" >
                              Order #{order.id}
                            </Typography>
                          </CardContent>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: "space-between",
                              padding: "16px",
                              alignItems: 'center',
                            }}
                          >
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "space-around", alignItems: 'center' }}>
                              <Typography sx={{ fontSize: "12px", color: "#D6D3D1" }} variant="subtitle1" color="text.secondary" component="div">
                                Client: &nbsp;
                              </Typography>
                              <Typography sx={{ fontSize: "12px" }} variant="subtitle1" component="div">
                                James Hetfield
                              </Typography>
                            </Box>
                            <Typography sx={{ fontSize: "12px" }} variant="subtitle1" component="div">
                              {this.formatTime(order.created_at)}
                            </Typography>
                          </Box>
                        </Box>
                      </Card>
                    ))}
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    {this.state.tab === 'three' && this.state.orders.delivered.map(order => (
                      <Card
                        variant="outlined"
                        data-test-id={'newModal'}
                        onClick={() => this.openAcceptOrDeliverModal('deliver', order.id)}
                        sx={{ display: 'flex', backgroundColor: "#AE7300", borderRadius: "8px", width: "100%", color: "#ffffff", marginBottom: "10px" }}
                      >
                        <Box sx={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
                          <CardContent >
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: 'center' }}>
                              <Typography sx={{ fontSize: "14px", fontWeight: 700 }} variant="subtitle1" >
                                Order #{order.id}
                              </Typography>
                              {order.status === "delivered" ? (
                                <Chip label="Delivered" sx={{ color: "#ffffff", backgroundColor: "#55D9B4" }} />
                              ) : (
                                <Chip label="Waiting for client" sx={{ color: "#ffffff", backgroundColor: "#01BAEC" }} />
                              )}
                            </Box>
                          </CardContent>
                          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: 'center', padding: "16px" }}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "space-around", alignItems: 'center' }}>
                              <Typography sx={{ fontSize: "12px", color: "#D6D3D1" }} variant="subtitle1" color="text.secondary" component="div">
                                Client: &nbsp;
                              </Typography>
                              <Typography variant="subtitle1" sx={{ fontSize: "12px" }} component="div">
                                James Hetfield
                              </Typography>
                            </Box>
                            <Typography component="div" sx={{ fontSize: "12px" }} variant="subtitle1" >
                              {this.formatTime(order.created_at)}
                            </Typography>
                          </Box>
                        </Box>
                      </Card>
                    ))}
                  </Grid>
                </Grid>
                <AcceptOrDeliverModal openDeclineModal={this.openDeclineModal} type={this.state.type} handleOpen={this.openAcceptOrDeliverModal} getOrdersList={this.getOrdersList} open={this.state.isAcceptOrDeliver} handleClose={this.closeAcceptOrDeliverModal} orders={this.state.orders} orderId={this.state.orderId} />
                <DeclineOrderModal closeAcceptOrDeliverModal={this.closeAcceptOrDeliverModal} handleOpen={this.openDeclineModal} open={this.state.isDecline} handleClose={this.closeDeclineModal} orders={this.state.orders} orderId={this.state.orderId} />
              </Box>
            )}
          </>
          ) :
          (<Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            p: 3,
            gap: '15px',
            minHeight: '50vh'
          }}>
            <img src={require("../assets/bakery-close.svg")} />
            <Typography variant="h6" sx={{
              fontSize: '24px',
              fontWeight: 700,
            }}>
              Your&nbsp;
              <span style={{ color: "#C2B30A" }}>
                Bakery&nbsp;
              </span>
              <span style={{ color: "#ffffff", marginLeft: "3px" }}>
                is closed
              </span>
            </Typography>
            <Typography variant="body1">
              Your store is currently closed. Please open it to <br />
              start receiving orders.
            </Typography>

            <Button
              onClick={this.openBakeryOpenModal}
              data-testid="opnBtn"
              sx={{
                width: '343px',
                height: '55px',
                padding: '16px 24px',
                borderRadius: '8px',
                color: '#ffffff',
                backgroundColor: '#C2B30A',
                '&:hover': {
                  backgroundColor: '#a59a00',
                },
                fontSize: '18px',
                fontWeight: 700,
                textTransform: 'none'
              }}
            >Open Store</Button>
            <OpenBakeryModal handleOpen={this.openBakeryOpenModal} open={this.state.isBakeryOpen} handleClose={this.closeBakeryOpenModal} openStore={this.openStore} />

          </Box>)}
      </Box>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

// Customizable Area End
