import { BlockComponent } from "../../../framework/src/BlockComponent";

export const configJSON = require("./config");
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export interface Props {
    navigation: any;
    id: string;
    history: { push: any };
}

interface S {
    token: string;
    tab: string;
    quantity: number,
    tip: number,
    cartItems: any[]
}

interface SS {
    id: any;
}

export default class PaymentsController extends BlockComponent<Props, S, SS> {
    getProductApiCallId: any;
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];
        this.state = {
            token: "",
            tab: "1",
            quantity: 1,
            tip: 0.1,
            cartItems: [
                {
                  id: 1,
                  name: 'Chocolate Brownies',
                  description: 'Indulge in our rich, fudgy chocolate brownies! Made with high-quality butter, sugar, and cocoa powder, each bite melts in your mouth.',
                  price: 20.0,
                  discountedPrice: 18.0,
                  imageUrl: 'https://via.placeholder.com/60',
                },
                {
                  id: 2,
                  name: 'Blueberry Muffin',
                  description: 'A soft and moist muffin packed with fresh blueberries, perfect for a quick snack or breakfast treat.',
                  price: 15.0,
                  discountedPrice: 12.0,
                  imageUrl: 'https://via.placeholder.com/60',
                },
              ],
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    }

    async componentDidMount() {
        this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
        super.componentDidMount();
    }

    handleChange = (e: any, newValue: any) => {
        this.setState({ tab: newValue });
    };



    handleSelect = (event: any) => {
        this.setState({ token: event.target.value });
    };

    handleTipChange = (event: any) => {
        this.setState({ tip: parseFloat(event.target.value) });
    };


}
