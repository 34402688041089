import React from "react";

interface Props {
  password: any,
  valid: any,
  invalid: any
}

const PasswordStrengthIndicator = (props: Props) => {
  const requirements = [
    {
      label: "At least one capital letter",
      condition: /[A-Z]/.test(props.password)
    },
    {
      label: "At least one number",
      condition: /\d/.test(props.password)
    },
    {
      label: "At least one lowercase letter",
      condition: /[a-z]/.test(props.password)
    },
    {
      label: "Minimum character length is 8 characters",
      condition: props.password.length >= 8
    },
  ];

  return (
    <div style={{ width: "100%", color: "white", fontSize: "14px"}}>
      {requirements.map((req, index) => (
          <p key={index} style={{
            display: "flex", alignItems: "center", gap: "10px",
            fontFamily: "Cerebri Sans"
          }}>{req.condition ? <img style={{ width: props.valid ? "19px" : "15px" }} src={props.valid} alt="correct" /> : <img style={{ width: "15px", marginLeft:"1px" }} src={props.invalid} alt="wrong" />}{req.label}</p>
      ))}
    </div>
  );
};

export default PasswordStrengthIndicator;
